<template>
  <v-card class="order">
    <v-alert :value="createdSuccess" type="success" class="my-0"
      >Заказ создан</v-alert
    >
    <v-alert :value="errorMessage" type="error" class="my-0">
      {{errorAlert}}
    </v-alert>
    <v-form ref="form" lazy-validation>
      <v-card-title class="px-4 py-2">
        <span class="headline">Создание заказа</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-4 pt-1" @click="autocompleteClose">
        <v-layout row wrap>
          <v-flex
            :xs7="editedItem.delivery_type_id === 2"
            style="padding-right: 15px;"
          >
            <v-text-field
              label="КТО"
              v-model="userName"
              hide-details
              readonly
              @change="handleDirty"
            ></v-text-field>
            <v-layout row wrap>
              <v-flex :xs6="editedItem.delivery_type_id === 2">
                <v-select
                  label="Т/С"
                  :items="tsList"
                  :rules="[v => v.length > 0 || 'Заполните поле']"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.order_source_ids"
                  hide-details
                  class="mb-4"
                  chips
                  multiple
                  @change="handleDirty"
                ></v-select>
                <v-select
                  v-if="shouldDisplayStoreSelect"
                  label="Список магазинов"
                  :items="storeList"
                  :rules="[v => !!v || 'Заполните поле']"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.store_id"
                  hide-details
                  class="mb-4"
                  @change="handleDirty();"
                ></v-select>

                <v-select
                  label="Способ доставки"
                  :items="deliveryList"
                  :rules="[v => !!v || 'Заполните поле']"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.delivery_type_id"
                  hide-details
                  class="mb-4"
                  @change="handleDirty();"
                ></v-select>

                <v-menu
                  :close-on-content-click="false"
                  v-model="dataPicker"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  class="mb-2"
                  v-if="editedItem.delivery_type_id !== 2"
                  @change="handleDirty"
                >
                  <v-text-field
                    slot="activator"
                    label="Дата доставки"
                    :rules="[v => !!v || 'Заполните поле']"
                    v-model="editedItem.date"
                    prepend-icon="event"
                    hide-details
                    readonly
                    @change="handleDirty"
                  ></v-text-field>
                  <v-date-picker
                    v-model="editedItem.date"
                    @input="dataPicker = false"
                    no-title
                    scrollable
                    locale="ru-ru"
                    first-day-of-week="1"
                    @change="handleDirty"
                  ></v-date-picker>
                </v-menu>

                <v-layout row wrap>
                  <v-flex xs7>
                    <v-text-field
                      label="Время доставки"
                      :rules="[v => !!v || 'Заполните поле']"
                      v-model="editedItem.delivery_time"
                      hide-details
                      class="mb-4"
                      v-if="editedItem.delivery_type_id !== 2"
                      @change="handleDirty"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs5>
                    <v-select
                      label="Время суток"
                      :items="deliveryTimeOfDayList"
                      :rules="[v => !!v || 'Заполните поле']"
                      item-text="name"
                      item-value="id"
                      v-model="editedItem.times_of_day"
                      hide-details
                      class="mb-4"
                      v-if="editedItem.delivery_type_id !== 2"
                      @change="handleDirty"
                    ></v-select>
                  </v-flex>
                </v-layout>

                <v-select
                  label="Статус"
                  :items="statusList"
                  :rules="[v => !!v || 'Заполните поле']"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.order_status_id"
                  hide-details
                  class="mb-4"
                  @change="handleDirty"
                ></v-select>

                <v-autocomplete
                  label="Клиент"
                  :items="clientsList"
                  item-text="searchName"
                  item-value="id"
                  v-model.number="editedItem.client_id"
                  hide-details
                  class="mb-4"
                  no-data-text="Не надено"
                  clearable
                  :search-input.sync="searchClients"
                  @change="
                    handleDirty();
                    setDataClient();
                  " 
                ></v-autocomplete>

                <v-select
                  label="Тип клиента"
                  :items="typeClient"
                  :rules="[v => !!v || 'Заполните поле']"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.client_type_id"
                  hide-details
                  class="mb-4"
                  @change="handleDirty"
                ></v-select>

                <v-text-field
                  label="Имя"
                  :rules="[v => !!v || 'Заполните поле']"
                  v-model="editedItem.client_name"
                  hide-details
                  class="mb-4"
                  @change="handleDirty"
                ></v-text-field>

                <v-text-field
                  label="Телефон"
                  :rules="[v => !!v || 'Заполните поле']"
                  v-model="editedItem.client_phone"
                  hide-details
                  class="mb-4"
                  @change="handleDirty"
                ></v-text-field>

                <v-layout row wrap>
                  <v-flex xs5>
                    <v-text-field
                      label="Сумма"
                      v-model="editedItem.cost"
                      hide-details
                      placeholder="0"
                      class="mb-4"
                      @change="handleDirty"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs7>
                    <v-text-field
                      label="Комментарий"
                      v-model="editedItem.total_cost_comment"
                      hide-details
                      placeholder=""
                      class="mb-4"
                      @change="handleDirty"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-text-field
                  label="Стоимость доствки"
                  v-model.number="editedItem.delivery_cost"
                  hide-details
                  placeholder="0"
                  class="mb-4"
                  v-if="editedItem.delivery_type_id !== 1"
                  @change="handleDirty"
                />

                <v-text-field
                  label="Предоплата"
                  v-model.number="editedItem.pre_payment"
                  hide-details
                  class="mb-4"
                  type="text"
                  @change="handleDirty"
                />

                <v-select
                  label="Канал поступления предоплаты"
                  clearable
                  :items="paymentTypesList"
                  class="mb-4"
                  v-model="editedItem.payment_type_id"
                  item-value="id"
                  item-text="name"
                  :rules="[
                    v =>
                      (!!editedItem.pre_payment || editedItem.already_paid) && !v
                        ? 'Заполните поле'
                        : false
                  ]"
                  @change="handleDirty();"
                />

                <v-checkbox
                  label="Оплачен"
                  v-model="editedItem.already_paid"
                  color="primary"
                  hide-details
                  class="mb-4"
                  @change="handleDirty()"
                ></v-checkbox>

                <v-checkbox
                  label="Фото по готовности"
                  v-model="editedItem.is_photo"
                  color="primary"
                  hide-details
                  class="mb-4"
                  @change="handleDirty()"
                ></v-checkbox>

                <v-checkbox
                  label="Звонить предварительно"
                  v-model="editedItem.is_phone_call"
                  color="primary"
                  hide-details
                  class="mb-4"
                  @change="handleDirty()"
                ></v-checkbox>
              </v-flex>

              <v-flex
                xs6
                v-if="editedItem.delivery_type_id === 2"
                style="padding-left: 15px;"
              >
                <v-select
                  label="Курьер"
                  :items="couriersList"
                  :rules="[v => !!v || 'Заполните поле']"
                  item-text="name"
                  item-value="id"
                  v-model.number="editedItem.courier_id"
                  no-data-text="Не надено"
                  hide-details
                  class="mb-4"
                  v-if="editedItem.order_status_id === 3"
                  @change="handleDirty"
                ></v-select>

                <v-menu
                  :close-on-content-click="false"
                  v-model="dataPicker"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  class="mb-2"
                >
                  <v-text-field
                    slot="activator"
                    label="Дата доставки"
                    :rules="[v => !!v || 'Заполните поле']"
                    v-model="editedItem.date"
                    prepend-icon="event"
                    hide-details
                    readonly
                    @change="handleDirty"
                  ></v-text-field>
                  <v-date-picker
                    v-model="editedItem.date"
                    @input="dataPicker = false"
                    @change="
                      skipQuery = false;
                      handleDirty();
                    "
                    no-title
                    scrollable
                    locale="ru-ru"
                    first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>

                <v-layout row wrap>
                  <v-flex xs7>
                    <v-text-field
                      label="Время доставки"
                      :rules="[v => !!v || 'Заполните поле']"
                      v-model="editedItem.delivery_time"
                      hide-details
                      class="mb-4"
                      @change="handleDirty"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs5>
                    <v-select
                      label="Время суток"
                      :items="deliveryTimeOfDayList"
                      :rules="[v => !!v || 'Заполните поле']"
                      item-text="name"
                      item-value="id"
                      v-model="editedItem.times_of_day"
                      @change="
                        handleDirty();
                      "
                      hide-details
                      class="mb-4"
                    ></v-select>
                  </v-flex>
                </v-layout>

                <v-checkbox
                  label="Инкогнито"
                  v-model="editedItem.incognito"
                  color="primary"
                  hide-details
                  class="mb-4"
                ></v-checkbox>

                <v-checkbox
                  label="Заказчик-получатель"
                  v-model="editedItem.is_recipient_client"
                  color="primary"
                  hide-details
                  class="mb-4"
                ></v-checkbox>

                <!-- <v-autocomplete
                  ref="addresseeSelect"
                  label="Получатель"
                  :items="clientsList"
                  item-text="name"
                  item-value="id"
                  v-model.number="editedItem.recipient_id"
                  hide-details
                  class="mb-4"
                  no-data-text="Не надено"
                  clearable
                  @change="
                    setDataAddressee();
                    handleDirty();
                  "
                  v-if="!editedItem.is_recipient_client"
                  :search-input.sync="addresseeName"
                ></v-autocomplete> -->

                <v-autocomplete
                  label="Получатель"
                  :items="recipientsList"
                  item-text="searchName"
                  item-value="id"
                  v-model.number="editedItem.recipient_id"
                  hide-details
                  class="mb-4"
                  no-data-text="Не надено"
                  clearable
                  v-if="!editedItem.is_recipient_client"
                  :search-input.sync="searchRecipients"
                  @change="
                    handleDirty();
                    setDataAddressee();
                  " 
                ></v-autocomplete>

                <v-text-field
                  label="Имя получателя"
                  v-model="editedItem.recipient_name"
                  hide-details
                  class="mb-4"
                  v-if="!editedItem.is_recipient_client"
                  :rules="[v => (!!v) || 'Заполните поле']"
                  @change="handleDirty"
                ></v-text-field>

                <v-text-field
                  label="Телефон получателя"
                  v-model="editedItem.addressee_phone"
                  hide-details
                  class="mb-4"
                  v-if="!editedItem.is_recipient_client"
                  @change="handleDirty"
                ></v-text-field>

                <autocomplete-address
                  :value="editedItem.address"
                  @address-updated="handleAddressUpdate"
                  @change="
                    updateAddress($event);
                    handleDirty();
                  "
                  :readonly="false"
                ></autocomplete-address>

                <v-text-field
                  label="Квартира"
                  v-model="editedItem.flat"
                  hide-details
                  class="mb-4"
                  :rules="[v => !!v || 'Заполните поле']"
                  @change="handleDirty"
                ></v-text-field>

                <v-text-field
                  label="Подъезд"
                  v-model="editedItem.entrance"
                  hide-details
                  class="mb-4"
                  :rules="[v => !!v || 'Заполните поле']"
                  @change="handleDirty"
                ></v-text-field>

                <v-text-field
                  label="Этаж"
                  v-model="editedItem.floor"
                  hide-details
                  class="mb-4"
                  :rules="[v => !!v || 'Заполните поле']"
                  @change="handleDirty"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <p class="mb-0">Букеты</p>
            <v-layout
              row
              wrap
              v-for="(bouquet, index) in editedItem.bouquets"
              :key="'bouquet-' + index"
              align-center
            >
              <v-flex xs7 class="pr-3">
                <v-textarea
                  label="Название"
                  :rules="[v => !!v || 'Заполните поле']"
                  auto-grow
                  v-model="bouquet.name"
                  row-height="6"
                  hide-details
                  class="mb-4"
                  @change="handleDirty"
                ></v-textarea>
              </v-flex>
              <v-flex xs2 class="pr-3">
                <v-text-field
                  label="Кол-во"
                  v-model.number="bouquet.count"
                  hide-details
                  class="mb-4"
                  @change="handleDirty; checkNumber(index)"
                ></v-text-field>
              </v-flex>
              <v-flex xs2 class="pr-3">
                <v-text-field
                  label="Место"
                  hide-details
                  class="mb-4"
                  v-model="bouquet.place"
                  @change="handleDirty"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-icon @click="bouquetDelete(index)">
                  delete
                </v-icon>
              </v-flex>
            </v-layout>
            <v-icon @click="bouquetAdd()">
              add
            </v-icon>

            <v-textarea
              label="Комментарий"
              auto-grow
              v-model="editedItem.comment"
              row-height="12"
              hide-details
              class="my-4"
              @change="handleDirty"
            ></v-textarea>
          </v-flex>

          <v-flex xs5 v-if="editedItem.delivery_type_id === 2">
            <div style="position: relative; height: 100%; overflow: hidden;">
              <!-- <delivery-map
                :delivery-time-of-day-list="this.deliveryTimeOfDayList"
                :edited-item="editedItem"
                :zones="deliveryZones"
                :placemarks="placemarks"
              /> -->

              <delivery-map
                :delivery-time-of-day-list="[]"
                :edited-item="editedItem"
                :zones="[]"
                :placemarks="[]"
              />
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions class="px-4 py-2">
        <v-btn @click.native="cancel()">Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="submitForm">Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import AutocompleteAddress from '../../components/AutocompleteAddressYandex.vue';
import inside from 'point-in-geopolygon';
import DeliveryMap from './deliveryMap.vue';
import { getDistance } from 'geolib';
import geocoder from 'geocoder';

const baseCoordinates = [53.186104, 50.1602];
const unSaveChangesText =
  'На форме есть несохраненные данные. Вы уверены, что хотите закрыть форму?';

export default {
  components: {
    yandexMap,
    ymapMarker,
    AutocompleteAddress,
    DeliveryMap,
  },
  data() {
    return {
      searchClients: '',
      timerClients: null,
      searchRecipients: '',
      timerRecipients: null,
      adminRole:'',
      editedItem: {
        address: '',
        recipient_name: '',
        addressee_phone: '',
        already_paid: false,
        bouquets: [
          {
            name: '',
            count: null,
            place: '',
          },
        ],
        client_id: null,
        client_name: '',
        client_phone: '',
        client_type_id: null,
        courier_id: null,
        comment: '',
        coordinates: [],
        cost: '',
        total_cost_comment: '',
        date: '',
        delivery_cost: 0,
        delivery_time: '',
        delivery_type_id: 1,
        entrance: '',
        flat: '',
        floor: '',
        incognito: false,
        is_recipient_client: false,
        order_source_ids: [],
        order_status_id: 1,
        payment_type_id: 0,
        pre_payment: 0,
        recipient_id: 0,
        times_of_day: '',
        is_photo: false,
        is_phone_call: false,
        store_id: 1,
      },
      paymentTypesList: [],
      tsList: [],
      deliveryList: [],
      statusList: [],
      typeClient: [],
      deliveryTimeOfDayList: [
        {
          name: 'Утро',
          id: 'morning',
        },
        {
          name: 'День',
          id: 'noon',
        },
        {
          name: 'Вечер',
          id: 'evening',
        },
      ],
      couriersList: [],
      clientsList: [],
      recipientsList: [],
      createdSuccess: false,
      userInfo: {},
      dataPicker: false,
      ordersList: [],
      storeList: [],
      coordsMap: [53.05, 50.101783],
      responsible: undefined,
      clientName: '',
      addresseeName: '',
      isDirty: false,
      skipQuery: true,
      inputAddress: '',
      errorMessage: false,
      errorAlert : '',
    };
  },
  watch: {
    'editedItem.address': function (newAddress) {
      console.log('newAddress', newAddress)
      this.handleDirty();
    },
    searchClients(val) {
      const findClient = this.clientsList.find((item) => item.searchName === val);
      if (findClient) return false;

      if (val && val.length >= 3) {
        if (this.timerClients) clearTimeout(this.timerClients);

        this.timerClients = setTimeout(() => {
          this.getClients(val, false);
        }, 500);
      } else {
        this.clientsList = [];
      }
    },
    searchRecipients(val) {
      const findRecipient = this.recipientsList.find((item) => item.searchName === val);
      if (findRecipient) return false;

      if (val && val.length >= 3) {
        if (this.timerRecipients) clearTimeout(this.timerRecipients);

        this.timerRecipients = setTimeout(() => {
          this.getClients(val, true);
        }, 500);
      } else {
        this.recipientsList = [];
      }
    },
  },
  computed: {
    shouldDisplayStoreSelect() {
      // Check conditions for displaying store select
      return (
        (parseInt(localStorage.getItem('store-id')) == 0 && this.adminRole == 'admin')
      );
    },
    deliveryZones() {
      return this.$store.state.deliveryZones;
    },
    placemarks() {
      return this.ordersList
        .filter(item => item.coordinates && item.coordinates.length === 2)
        .map(order => ({
          id: order.id,
          coordinates: order.coordinates,
        }));
    },
  },
  methods: {
    getStatus() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-status`;

      axios
        .get(url)
        .then((response) => {
          this.statusList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClientTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/client-types`;

      axios
        .get(url)
        .then((response) => {
          this.typeClient = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStoreList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;

      axios
        .get(url)
        .then((response) => {
          this.storeList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDeliveryTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-delivery-types`;

      axios
        .get(url)
        .then((response) => {
          this.deliveryList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOrdersSource() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-source`;

      axios
        .get(url)
        .then((response) => {
          this.tsList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCouriers() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users`;

      axios
        .get(url, {
          params: {
            group_id: 15,
          },
        })
        .then((response) => {
          this.couriersList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClients(searchVal, recipient = false) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients`;

      axios
        .get(url, {
          params: {
            name_or_phone: searchVal,
            page_limit: 999,
          },
        })
        .then((response) => {
          const items = response.data.map((item) => {
            const client = item;
            client.searchName = `${item.name} (${item.phone})`;
            if (item.bill !=0) {
              client.searchName += `(${item.bill})`;
            }
            if (item.comment) {
              client.searchName += `(${item.comment})`;
            }
            return client;
          });

          if (recipient) {
            this.recipientsList = items;
          } else {
            this.clientsList = items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPaymentTypesList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payment-types`;

      axios
        .get(url)
        .then((response) => {
          this.paymentTypesList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleBeforeUnload(e) {
      if (this.isDirty) {
        e.returnValue = unSaveChangesText;
      }
    },
    checkNumber(index) {
      let val = this.editedItem.bouquets[index].count;
      val = (!isNaN(val)) ? +val : 0;
      this.editedItem.bouquets[index].count = val;
    },
    handleDirty() {
      if (!this.isDirty) {
        this.isDirty = true;
      }
    },
    handleAddressUpdate(newAddress) {
      console.log('Received new address from child component:', newAddress);
      this.inputAddress = newAddress;
    },
    setDataClient() {
      const clientId = this.editedItem.client_id;
      const findClient = this.clientsList.find(item => item.id === clientId);

      if (findClient !== undefined) {
        this.editedItem.client_name = findClient.name;
        this.editedItem.client_phone = findClient.phone;
        this.editedItem.client_type_id = findClient.client_type_id;
        if (!this.editedItem.addre ) { 
          this.editedItem.address = findClient.address;
        }
        this.editedItem.flat = findClient.flat_number;
        this.editedItem.floor = findClient.floor;
        this.editedItem.entrance = findClient.entrance;
      } else {
        this.editedItem.client_name = '';
        this.editedItem.client_phone = '';
        this.editedItem.client_type_id = '';
        // this.editedItem.address = '';
        // this.editedItem.flat = '';
        // this.editedItem.floor = '';
        // this.editedItem.entrance = '';
      }
    },
    setDataAddressee() {
      const clientId = this.editedItem.recipient_id;
      const findClient = this.recipientsList.find(item => item.id === clientId);

      if (findClient) {
        this.editedItem.recipient_name = findClient.name;
        this.editedItem.addressee_phone = findClient.phone;
        if (!this.editedItem.addre ) { 
          this.editedItem.address = findClient.address;
        }
        this.editedItem.flat = findClient.flat_number;
        this.editedItem.floor = findClient.floor;
        this.editedItem.entrance = findClient.entrance;
      } else {
        this.editedItem.recipient_name = '';
        this.editedItem.addressee_phone = '';
        // this.editedItem.address = '';
        // this.editedItem.flat = '';
        // this.editedItem.floor = '';
        // this.editedItem.entrance = findClient.entrance;
      }
    },
    setPointByClientAddress(address) {
      geocoder.geocode(
        address,
        (
          _,
          {
            results: [
              { geometry: { location: { lat, lng } = {} } = {} } = {},
            ] = [],
          } = {},
        ) => {
          this.updateAddress({
            geo: [lat, lng],
            address,
          });
        },
        { language: 'ru', key: window.GOOGLE_API_KEY },
      );
    },
    updateAddress(data) {
      this.editedItem.address = data.address;
      console.log("address",data)

      if (data && data.geo[0] && data.geo[1] && this.editedItem) {
        this.editedItem.coordinates = data.geo;
        // this.editedItem.coordsMap = data.geo;
        this.calculateAndSetDeliveryCost(data.geo);
      }
    },
    calculateAndSetDeliveryCost(geo) {
      let findArea = false;

      this.deliveryZones.forEach((item) => {
        if (inside.polygon(JSON.parse(item.coordinates), geo) && !findArea) {
          // console.log("item", item);
          const distanceKm = getDistance(
            { longitude: geo[0], latitude: geo[1] },
            { longitude: baseCoordinates[0], latitude: baseCoordinates[1] }
          ) / 1000; // getDistance might return distance in meters, convert to km
          // console.log("geo[0]", geo[0]);
          // console.log("geo[1]", geo[1]);
          // console.log("distance in km", distanceKm);
          // console.log("price per km", item.priceForKm);
          
          this.editedItem.delivery_cost = item.priceForKm
            ? distanceKm * item.priceForKm
            : item.price;

          // this.editedItem.delivery_cost += this.editedItem.delivery_cost;

          findArea = true;
        }
      });
    },
    cancel() {
      if (this.isDirty) {
        const exitConfirmation = confirm(unSaveChangesText);

        if (!exitConfirmation) {
          return;
        }
      }

      this.editedItem = {};
      this.createdSuccess = false;
      this.errorMessage = false;
      this.$router.push('/orders/');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        // if (this.editedItem.client_id) {
        //   this.addOrder();
        // } else {
        //   this.addClient();
        // }
        this.addOrder();
      }
    },
    addOrder() {
      const propsItem = Object.assign({}, this.editedItem);

      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders`;

      propsItem.coordinates = propsItem.coordinates.join(',');

      if (propsItem.delivery_type_id === 1) {
        propsItem.address = '';
        propsItem.flat = '';
        propsItem.floor = '';
        propsItem.entrance = '';
        propsItem.coordinates = '';
        propsItem.recipient_id = 0;
        propsItem.recipient_name = '';
        propsItem.addressee_phone = '';
        propsItem.courier_id = null;
      }

      propsItem.addressee_name = propsItem.recipient_name;
      propsItem.cost = propsItem.cost.toString();
      // if (!propsItem.coordinates) {
      //   propsItem.coordinates = [];
      // }
      axios
        .post(url, propsItem)
        .then(() => {
          this.createdSuccess = true;

          setTimeout(() => {
            this.$router.push('/orders/');
          }, 1000);
        })
        .catch((error) => {
          this.errorMessage = true;
            
            if (error.response) {
              if (error.response.status === 400 || error.response.status === 500) {
                  if (error.response.data && error.response.data.error) {
                    this.errorAlert = error.response.data.error;
                    // setTimeout(() => {
                    //   this.$router.push('/orders/');
                    // }, 1000);
                  }
              }
            }
        });
    },
    addClient() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients`;
      const propsItem = {
        active: true,
        address: this.editedItem.recipient_address,
        bill: 0,
        birth_day: '1900-01-01',
        client_type: this.editedItem.client_type,
        flat_number: this.editedItem.recipient_flat_number,
        floor: this.editedItem.recipient_floor,
        limit: 0,
        name: this.editedItem.client_name,
        phone: this.editedItem.client_phone,
        sale: 0,
      };

      axios
        .post(url, propsItem)
        .then((response) => {
          this.editedItem.client_id = response.data.id;
          this.addOrder();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    bouquetAdd() {
      this.editedItem.bouquets.push({
        name: '',
        count: null,
        place: '',
      });
    },
    bouquetDelete(index) {
      this.editedItem.bouquets.splice(index, 1);
    },
    autocompleteClose(e) {
      const selects = document.querySelectorAll('.v-autocomplete');
      const { target } = e;
      let isFind = false;

      for (let i = 0; i < selects.length; i += 1) {
        if (target === selects[i] || selects[i].contains(target)) {
          isFind = true;
        }
      }

      // if (!isFind) {
      //   this.$refs.clientSelect.blur();
      //   if (this.$refs.responsibleSelect) {
      //     this.$refs.responsibleSelect.blur();
      //   }
      //   if (this.$refs.addresseeSelect) {
      //     this.$refs.addresseeSelect.blur();
      //   }
      // }
    },
  },
  created() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  mounted() {
    // get store_id and admin role form logged in user
    const store_id = parseInt(localStorage.getItem('store-id'))
    this.editedItem.store_id = store_id
    const adminRole = JSON.parse(localStorage.getItem('user-group')).code
    this.adminRole = adminRole
    const userName = localStorage.getItem("user-name");
    this.userName = userName

    this.getPaymentTypesList();
    this.getCouriers();
    this.getStatus();
    this.getClientTypes();
    this.getStoreList();
    this.getDeliveryTypes();
    this.getOrdersSource();
  },
};
</script>

<style lang="scss">
.order.v-card {
  position: relative;
}
.order .v-card__text {
  padding-bottom: 52px;
}
.order .v-card__actions {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0 5px;
}
</style>
